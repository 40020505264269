
import { Options, Vue } from 'vue-class-component';
import { MatchClient, TryAgainClient } from '@/services/Services';
import { Prop } from 'vue-property-decorator';
import LoveFaceShadowBar from '@/components/loveFaceShadowBar.vue';
import * as OM from '@/Model';
import InfoModal from '@/components/modals/infoModal.vue';
import bodymovin from "lottie-web";
import Hexagon from '@/components/hexagon.vue';
import { StorageServices } from '@/services/StorageServices';
import AcceptAffinityFinderInfoModal from '../modals/acceptAffinityFinderInfoModal.vue';

@Options({
    components: {
        LoveFaceShadowBar,
        Hexagon
    }
})
export default class AffinityResultReplierHasToConfirmAffinity extends Vue {

    @Prop({
        default: () => new OM.NewMatchVm()
    }) match: OM.NewMatchVm;

    @Prop({
        default: 0
    }) affinity: number;

    @Prop({
        default: () => []
    }) answers: OM.NewMatchQuestion[];

    affinityRateText: string = "";
    animationDone: boolean = false;

    created() {
        this.affinityRateText = this.$localizationService.getLocalizedValue("app_AffinityRate:", "Affinity Rate:");

        if(!this.match.isOverMinimumAffinity){
            this.animationDone = true;
            return;
        }

        setTimeout(() => {
            this.animationDone = true;
        }, 2800);
    }

    mounted() {
        if(this.animationDone)
            return;

        setTimeout(() => {
            var animation = bodymovin.loadAnimation({
                container: <any>this.$refs.lottieAnimation,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: './json/registrazioneriuscita.json'
            })

            animation.play();
            animation.loop = false;
        }, 300);
    }

    removeAffinity() {
        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("app_UnaVoltaEliminataAffinitàNonPotràEssereRecuperata", "Una volta eliminata l'affinità non potrà più essere recuperata. <br />Vuoi procedere?"),
            confirm: this.$localizationService.getLocalizedValue("app_si", "Si"),
            confirmCb: () => {
                this.$opModal.closeLast();
                MatchClient.takeOffAffinity(this.match.matchIdentifier)
                .then(x => {
                    if(this.match.isSecondChance)
                        this.$router.replace("/affinity");
                    else
                        this.$router.replace("/finder");
                })
            },
            deny: this.$localizationService.getLocalizedValue("app_no", "No"),
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

    goToChat() {
        this.$router.replace('/chats/' + this.match.chatRoomIdentifier);
    }

    createChat(){
        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("app_UnaVoltaAccettatoSiCreeràDirettamenteLaChatConQuestoProfilo.<br/>Vuoiprocedere?", "Una volta accettato si creerà direttamente la chat con questo profilo. <br />Vuoi procedere?"),
            confirm: this.$localizationService.getLocalizedValue("app_si", "Si"),
            confirmCb: () => {

                var confirmAffinityVM: OM.ConfirmAffinityVM = {
                    affinityIdentifier: this.match.matchIdentifier
                }

                MatchClient.confirmAffinity(confirmAffinityVM)
                .then(x => {
                    this.$opModal.closeLast()
                    this.match.chatRoomIdentifier = x;
                    this.goToChat();
                })
                .catch(err => {
                    this.$opModal.show(InfoModal, {
                        img: 'face_error.svg',
                        text: err.Message,
                        confirm: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                        confirmCb: () => {
                            this.$opModal.closeLast();
                        },
                    })
                })
            },
            deny: this.$localizationService.getLocalizedValue("app_no", "No"),
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }
}
