
import { Options, Vue } from 'vue-class-component';
import { TryAgainClient } from '@/services/Services';
import { Prop } from 'vue-property-decorator';
import LoveFaceShadowBar from '@/components/loveFaceShadowBar.vue';
import * as OM from '@/Model';

@Options({
    components: {
        LoveFaceShadowBar
    }
})
export default class AffinityNotReached extends Vue {

    @Prop() chatRoomIdentifier: string;
    @Prop() affinityIdentifier: string;
    @Prop() experienceIdentifier: string;

    @Prop({
        default: 0
    }) affinity: number;

    @Prop({
        default: 0
    }) minimumAffinityRate: number;

    @Prop({
        default: null
    }) premium: OM.PremiumActiveVM;

    @Prop({
        default: '/finder'
    }) replaceUrl: string;
    localReplaceUrl: string = "";

    @Prop({
        default: ''
    }) userName: string;

    affinityRateText: string = "";
    tryAgainSummary = new OM.TryAgainSummaryVM();

    created() {
        this.localReplaceUrl = this.replaceUrl;
        this.affinityRateText = this.$localizationService.getLocalizedValue("app_AffinityRate:", "Affinity Rate:");

        if(this.chatRoomIdentifier)
            this.localReplaceUrl = "/chats/" + this.chatRoomIdentifier;

        this.init();
    }

    init() {
        Promise.all([
            TryAgainClient.getTryAgainSummary(this.affinityIdentifier, false),
        ])
        .then(xs => {
            this.tryAgainSummary = xs[0];
        })
    }

    get endDate(){
        if(this.tryAgainSummary && this.tryAgainSummary.nextTryAgainAvailable)
            return new Date(this.tryAgainSummary.nextTryAgainAvailable);

        return null;
    }

    nextItem() {
        this.$router.replace(this.localReplaceUrl);
    }
}
