
import { Options, Vue } from 'vue-class-component';
import { MatchClient, PremiumClient, StripeClient, TryAgainClient } from '@/services/Services';
import LoveFaceShadowBar from '@/components/loveFaceShadowBar.vue';
import Question from '../components/question.vue';
import Answer from '../components/answer.vue';
import affinityResultReplierHasToConfirmAffinity from '../components/affinityResultReplierHasToConfirmAffinity.vue';
import AffinityNotReached from '../components/affinityNotReached.vue';
import AffinitySuccess from '../components/affinitySuccess.vue';
import BombTriggered from '../components/bombTriggered.vue';
import BombTriggeredNotBlocked from '../components/bombTriggeredNotBlocked.vue';
import InfoModal from '@/components/modals/infoModal.vue';
import noPremiumModal from '../modals/premium/noPremiumModal.vue';
import store from '@/store';
import * as OM from '@/Model';
import * as VM from '@/viewModel';
import { newMatchCallback } from '@/afterPremiumActionCallbacks';
import { UrlServices } from '@/services/UrlServices';

@Options({
	components: {
		LoveFaceShadowBar,
		Question,
		Answer,
        affinityResultReplierHasToConfirmAffinity,
		AffinityNotReached,
        AffinitySuccess,
        BombTriggered,
        BombTriggeredNotBlocked
	}
})
export default class NewMatch extends Vue {

	premium: OM.PremiumActiveVM = null;
	match: OM.NewMatchVm = new OM.NewMatchVm();

    matchIdentifier: string = "";
    affinityRateText: string = "";
	matchStatus = OM.ReplyStatus;
	questionIndex: number = 0;
	loaded: boolean = false;
	error: boolean = false;

	hideBombTriggeredButCanProceed: boolean = false;

    created() {
        this.affinityRateText = this.$localizationService.getLocalizedValue("app_AffinityRate:", "Affinity Rate:");
		this.matchIdentifier = this.$route.params.matchIdentifier.toString();

        this.init();
	}

    init() {
        Promise.all([
            MatchClient.getNewMatchInformations(this.matchIdentifier),
            PremiumClient.getActive()
        ])
        .then(xs => {
            this.match = xs[0];
            this.premium = xs[1];
        })
        .catch(err => {
            this.error = true;
            this.$opModal.show(InfoModal, {
                img: 'face_error.svg',
                text: err.Message,
                confirm: this.$localizationService.getLocalizedValue("app_Finder", "Finder"),
                confirmCb: () => {
                    this.$router.push("/finder")
                    this.$opModal.closeLast();
                },
            })
        }).finally( () => {
            this.loaded = true;
        })
    }

    get isBombTriggeredAndCantProceed(){
        return this.match.isBombTriggered && !this.match.replierHasToConfirmAffinity;
    }

    get isBombTriggeredAndCanProceedAndInfoShow(){
        return this.match.isBombTriggered && this.match.replierHasToConfirmAffinity && !this.hideBombTriggeredButCanProceed;
    }

    get isBombTriggeredAndCanProceedAndInfoHide(){
        return this.match.isBombTriggered && this.match.replierHasToConfirmAffinity && this.hideBombTriggeredButCanProceed;
    }

    get showLayout(){
        return !this.showSuccessError && (!this.match.isBombTriggered || this.isBombTriggeredAndCanProceedAndInfoHide)
    }

    closeAffinity(){
        if(!this.match.isSecondChance)
            this.$router.replace('/finder')
        else 
            this.$router.replace('/affinity')
    }

	get currentQuestion() {
		var question = this.match.questions[this.questionIndex];

		return question;
	}

	get currentAnswer() {
		return this.match.questions[this.questionIndex];
	}

	get showAnswer(){
		if(this.questionIndex > this.match.questions.length - 1)
			return false;

		return this.match.questions[this.questionIndex].answerIndex != -1;
	}

    get showSuccessError() {
        return this.match.status == this.matchStatus.Completed && !this.showAnswer;
    }

	reply(answerIndex: number, answerText: string) {
		var replyModel = new OM.ReplyMatchQuestionVm();
		replyModel.matchIdentifier = this.match.matchIdentifier;
		replyModel.questionIndex = this.questionIndex;
		replyModel.answerIndex = answerIndex;
		replyModel.answerText = answerText;

		MatchClient.replyQuestion(replyModel)
		.then(x => {
			this.match = x;

            this.scrollPagePaddingTop();
		})
        .catch(err => {
            this.$opModal.show(InfoModal, {
                img: 'face_error.svg',
                text: err.Message,
                confirm: err.CallbackParams != null ? err.CallbackParams.CallbackText : this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                confirmCb: () => {
                    if(err.CallbackParams){
                        if(err.CallbackParams.OpenBlank)
                            UrlServices.openBlank(err.CallbackParams.CallbackRoute)
                        else 
                            this.$router.push(err.CallbackParams.CallbackRoute);
                    }

                    this.$opModal.closeLast();
                },
                deny: err.RefuseCallbackParams != null ? err.RefuseCallbackParams.CallbackText : null,
                denyCb: !err.RefuseCallbackParams ? null : () => {
                    if(err.RefuseCallbackParams.OpenBlank)
                        UrlServices.openBlank(err.RefuseCallbackParams.CallbackRoute)
                    else 
                        this.$router.push(err.RefuseCallbackParams.CallbackRoute);
                    
                    this.$opModal.closeLast();
                }
            })
        })
	}

    scrollPagePaddingTop(){
        var el = this.$refs.page_padding_scrollable as HTMLElement;
        el.scrollTop = 0;
    }

	prevQuestion() {
		if(this.questionIndex > 0){
			this.questionIndex = this.questionIndex - 1;
            this.scrollPagePaddingTop();
        }
	}
	nextQuestion() {
		if(this.questionIndex < this.match.questions.length){
			this.questionIndex = this.questionIndex + 1;
            this.scrollPagePaddingTop();
        }
	}

	openAnswerDetail(questionIndex: number) {
		this.questionIndex = questionIndex;
	}

    canTryAgain() {
        TryAgainClient.canTryAgain(false)
        .then( x => {
            if(!x){
                this.cannotUseTryAgain();
                return;
            }

            this.useTryAgain();
        })
    }

    cannotUseTryAgain(){
        var text = this.$localizationService.getLocalizedValue("app_HaiFinitoIRiprovaDisponibili!", "Hai finito i Riprova disponibili!");

        if(this.premium) {
            this.$opModal.show(InfoModal, {
                text: text,
                confirm: this.$localizationService.getLocalizedValue("app_HoCapito", "Ho capito"),
                confirmCb: () => {
                    this.$opModal.closeLast();
                }
            })

            return;
        }

        this.$opModal.show(noPremiumModal, {
            text: text,
            actionText: this.$localizationService.getLocalizedValue("app_DiventaPremium", "Diventa premium"),
            callback: () => {
                this.$opModal.closeLast();
                store.state.afterPremiumAction = newMatchCallback(this.match.matchIdentifier);

                this.$router.push('/premium/');
            }
        })
    }

    useTryAgain(){
        this.$opModal.show(InfoModal, {
            text: this.$localizationService.getLocalizedValue("app_ConfermiDiVolerUtilizzareIlRiprova?", "Confermi di voler utilizzare il \"Riprova\"?"),
            confirm: this.$localizationService.getLocalizedValue("app_Si", "Si"),
            deny: this.$localizationService.getLocalizedValue("app_No", "No"),
            confirmCb: () => {
                this.$opModal.closeLast();

                TryAgainClient.useTryAgain(this.match.matchIdentifier)
                .then(x => {
                    this.questionIndex = 0;
                    this.init();
                })
                .catch(err => {
                    this.$opModal.show(InfoModal, {
                        img: 'face_error.svg',
                        text: err.Message,
                        deny: this.$localizationService.getLocalizedValue("app_Chiudi", "Chiudi"),
                        denyCb: () => {
                            this.$opModal.closeAll();
                        }
                    })
                })
            },
            denyCb: () => {
                this.$opModal.closeLast();
            }
        })
    }

}
